<template>
  <modal
    name="featured-flight-info"
    transition="pop-out"
    :adaptive="true"
    @before-open="setEmptyLegId"
  >
    <div style="width: 500px; background-color: #37373e; background-image: url('/img/bg.svg') ; border-radius: 32px;">
      <div style="padding: 60px 30px;">
        <div
          style="margin-bottom: 15px;"
          v-for="(item, index) of bookings"
          :key="index"
          >
          <div style="color: #fff; line-height: 1.4em;">
            <p>Booking No: {{item.booking.number}}</p>
            <p>Name: {{item.booking.createdBy.firstName}} {{item.booking.createdBy.lastName}}</p>
            <p>Email: {{item.booking.createdBy.email}}</p>
            <p>Phone: {{item.booking.createdBy.mobile}}</p>
            <p>Pax: {{item.booking.numPax}}</p>
          </div>
        </div>
        <div class="modal__close transaction-popup__close" style="position: absolute; top: 32px; right: 32px;" @click="closePopup">
          <SvgIcon name="close-white"/>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import { bookingAPI } from '@/api/booking'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './TransactionPopup.styl'

  export default {
    name: 'FeaturedFlightsPopup',
    data: () => ({
      bookings: [],
    }),
    methods: {
      async setEmptyLegId (event) {
        try {
          const response = await bookingAPI.getFlightsFeaturedBookingInfo(event.params.emptyLegId);

          this.bookings = response.data;
        } catch (e) {
           console.log(e.message)
        }
      },
      closePopup () {
        this.$emit('close')
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
