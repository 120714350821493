<template>
  <div
  class="bookingDashboard bookingDashboard--list"
  :class="'bookingDashboard--' + dashboardType"
  >
    <div class="bookingDashboard__header">
      <div class="bookingDashboard__title">Featured Flights</div>
      <router-link v-if="dashboardType === 'admin'" class="signUpDashboard__button" to="">
        <span
          @click="exportToExcel()"
          class="text">
            Export</span>
      </router-link>
    </div>
    <div class="bookingDashboard__table" style="width:100%;">
      <div class="bookingDashboard__table-header">
        <div class="bookingDashboard__table-cell">№</div>
        <div class="bookingDashboard__table-cell">Date</div>
        <div class="bookingDashboard__table-cell">From</div>
        <div class="bookingDashboard__table-cell">To</div>
        <div class="bookingDashboard__table-cell">Pax</div>
      </div>
      <div class="bookingDashboard__table-body">
        <vue-scroll>
          <div
            v-for="(item, index) of flights"
            :key="index"
           class="bookingDashboard__table-row">
            <div class="bookingDashboard__table-cell">
              <div class="bookingDashboard__table-index">{{flights.length - index}}</div>
            </div>
            <div class="bookingDashboard__table-cell">
              <span class="bookingDashboard__table-caption" style="color: #000000;">{{item.departureDate.substring(0, item.departureDate.indexOf('T'))}}</span>
            </div>
            <div class="bookingDashboard__table-cell">
              <span class="bookingDashboard__table-caption">{{item.departure}}</span>
            </div>
            <div class="bookingDashboard__table-cell">
              <span class="bookingDashboard__table-caption">{{item.arrival}}</span>
            </div>
            <div class="bookingDashboard__table-cell" style="align-items: center; justify-content: space-between">
              <span class="bookingDashboard__table-caption">{{item.pax}}/{{item.aircraftPax}}</span>
              <a
                class="bookingDashboard__table-name"
                style="padding-left: 10px;"
                @click="$modal.show('featured-flight-info', { emptyLegId: item.emptyLegId })"
              >
              Info
              </a>
              <a
                class="bookingDashboard__table-name" style="padding-left: 10px; color: #000000;">Delete</a>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
    <FeaturedFlightsPopup @close="closePopup"/>
  </div>
</template>

<script>
import {bookingAPI} from '@/api/booking';
import { reportsAPI } from '@/api/reports';
import '../SignUpDashboard/SignUpDashboard.styl'
import FeaturedFlightsPopup from '@/components/common/modals/FeaturedFlightsPopup.vue';

  export default {
    name: 'FeaturedFlights',
    props: ['dashboardType'],
    data () {
        return {
          flights: [],
        }
    },
    async created () {
        try {
          const { data } = await bookingAPI.getFlightsFeatured();
          this.flights = data;
        } catch (e) {
          console.log(e.message)
        }
    },
    methods: {
        async exportToExcel () {
          try {
            const { data } = await reportsAPI.featuredFlights();

            const downloadLink = document.createElement('a');
            const blob = new Blob(['\ufeff', data]);
            const url = window.URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = 'featured-flights_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          } catch (e) {
            console.log(e)
          }
        },
        closePopup () {
          this.$modal.hide('featured-flight-info')
        },
    },
    components: { FeaturedFlightsPopup },
}
</script>
